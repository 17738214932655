import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Experience } from '@ninetailed/experience.js-next';
import { ExperienceMapper } from '@ninetailed/experience.js-utils';
import throttle from 'lodash/throttle';

// Types
import * as Types from './types';

// Style
import * as Styles from './styles';
import { navHeightDesktop, zIndexMasterList } from 'styles';

// Icons
import { MdMenu } from 'react-icons/md';

// Assets
import AmpIcon from 'assets/logo/logo-ampersand.svg';

// Components
import Link from 'next/link';
import {
  useMediaQuery,
  breakpointRules,
  IconFavourite,
  IconFavouriteFilled,
  IconShoppingBag,
  IconAccount,
  IconSearch,
  IconClose,
  IconBack,
  Button
} from '@aceandtate/ds';
import Backdrop from 'components/Backdrop';
import BrandLogo from 'components/BrandLogo';
import ConditionalLink from 'components/ConditionalLink';
import Search from 'components/Search';
import Visibility from 'components/Visibility';
import { Submenu, MenuNavIcon, Warning, SecondaryMobileMenu, MenuPane } from './partials';
import { WishlistAction } from 'features/Wishlist';

// Helpers
import { useCartCount } from 'services/cartService';
import { useUserState } from 'services/userService';
import { paths, usePath } from 'paths';
import { getCurrentPath } from 'paths/helpers';
import messages from './messages';
import { headerInteraction } from 'tracking';
import { useRouter } from 'next/router';
import getMenuBlocksForPath from './utils/getMenuBlocksForPath';
import DynamicIcon from 'components/DynamicIcon';

type MenuNavigationProps = {
  menu: Types.Menu;
  hasBorder?: boolean;
  top?: number;
  menuTextColor?: string;
};

export default function MenuNavigation(props: MenuNavigationProps) {
  const { menu, hasBorder, menuTextColor } = props;

  const [isPrimaryMenuOpen, setIsPrimaryMenuOpen] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSubmenuOpen, setIsMobileSubmenuOpen] = useState(false);

  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [topBannerHeight, setTopBannerHeight] = useState(0);
  const [topBannerVisible, setTopBannerVisible] = useState(true);

  const { hasValidAccessToken } = useUserState();

  const intl = useIntl();
  const pathTo = usePath();
  const router = useRouter();

  const previousScrollY = useRef<number>(0);
  const topBannerRef = useRef<HTMLDivElement>(null);
  const isMobile = !useMediaQuery(breakpointRules.laptop);

  const isAnyMenuOpen = isPrimaryMenuOpen || isMobileMenuOpen || isSearchMenuOpen;
  const cartCount = useCartCount();

  const menuBlocks = getMenuBlocksForPath(menu.menuCollection.items, getCurrentPath(router.asPath));
  const mobileSubmenuSubtitles = menuBlocks?.filter((menu: Types.Menu) => menu.displayOn === 'mobile');
  const secondaryMobileMenuBlocks = menuBlocks?.filter(menu => menu.displayOn === 'secondaryMobile');
  const stickyCTAMenuBlock = menuBlocks?.find(menu => menu.displayOn === 'stickyCTA');

  const isHomepage = router.pathname === '/';
  const isTransparent = isHomepage && previousScrollY.current < topBannerHeight + navHeightDesktop;

  useEffect(() => {
    previousScrollY.current = window.scrollY;
    window.addEventListener('scroll', handleNavbarVisibility, {
      passive: true
    });
    window.addEventListener('resize', updateTopBannerHeight, {
      passive: true
    });
    updateTopBannerHeight();

    const handleRouteChange = () => closeAllMenus();

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      window.removeEventListener('scroll', handleNavbarVisibility);
      window.removeEventListener('resize', updateTopBannerHeight);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    // Handles correctly closing all menus on browser resize
    isAnyMenuOpen && closeAllMenus();
  }, [isMobile]);

  // STYLES SPECIFIC

  // conditionally showing / hiding navigation on scroll
  const handleNavbarVisibility = throttle(
    () => {
      const { scrollY } = window;
      setNavbarVisible(scrollY < Math.max(topBannerHeight, previousScrollY.current));
      setTopBannerVisible(scrollY === 0);
      previousScrollY.current = scrollY;
      if (!isMobile) {
        setIsPrimaryMenuOpen(false);
      }
    },
    100,
    { leading: true }
  );

  const updateTopBannerHeight = () => {
    const element = topBannerRef.current;
    if (element && element.offsetHeight !== 0) {
      setTopBannerHeight(element.offsetHeight);
    }
  };

  // MENU SPECIFIC

  const openPrimaryMenu = () => {
    if (!isMobile && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
    setIsPrimaryMenuOpen(true);
  };

  const openMobileMenu = () => {
    isSearchMenuOpen && setIsSearchMenuOpen(false);
    setIsMobileMenuOpen(true);
  };

  const closeAllMenus = () => {
    closePrimaryMenu();
    closeMobileMenu();
    closeSearchMenu();
  };

  const closePrimaryMenu = () => {
    setIsPrimaryMenuOpen(false);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setIsMobileSubmenuOpen(false);
  };

  const closeSearchMenu = () => {
    setIsSearchMenuOpen(false);
  };

  const staticLinks = [
    {
      dataTestId: 'menu-nav.stores',
      fullPageReload: false,
      link: 'stores',
      url: paths.stores
    },
    {
      dataTestId: hasValidAccessToken ? 'menu-nav.account' : 'menu-nav.login',
      link: hasValidAccessToken ? 'account' : 'login',
      onClick: hasValidAccessToken
        ? undefined
        : () => {
            const searchParams = new URLSearchParams(window.location.search);

            if (!searchParams.get('auth')) {
              searchParams.append('auth', 'login');
            }

            router.query.auth = 'login';
            const newUrl = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
            router.push({ query: router.query }, newUrl, { shallow: true });
          },
      url: hasValidAccessToken ? paths.userProfile : undefined
    }
  ];

  function getUserProfilePath() {
    // avoids rehydration issues by waiting to bind the path until after reyhdration
    if (!router.isReady) return undefined;

    return hasValidAccessToken ? pathTo(paths.userProfile) : undefined;
  }

  return (
    <>
      <div ref={topBannerRef} className='noPrint' data-cs-capture>
        <Warning ribbonItems={menu.infoRibbonItemsCollection?.items || []} />
      </div>
      <Styles.GlobalNavContainer
        navVisible={navbarVisible}
        top={topBannerVisible ? topBannerHeight : 0}
        className='noPrint'
        hasBorder={hasBorder}
        data-cs-capture
      >
        <Styles.PrimaryMenuContainer
          menuTextColor={menuTextColor}
          isTransparent={isTransparent}
          isHomepage={isHomepage}
        >
          <Styles.Left>
            {isMobile && (
              <MenuNavIcon
                icon={isMobileMenuOpen ? <IconClose /> : <MdMenu />}
                label={
                  isMobileMenuOpen
                    ? intl.formatMessage(messages.closeMobileMenu)
                    : intl.formatMessage(messages.openMobileMenu)
                }
                onClick={isMobileMenuOpen ? closeMobileMenu : openMobileMenu}
              />
            )}
            <Link href={paths.home}>
              <Visibility visibleOn={['tablet', 'mobile']}>
                <Styles.NavbarIcon hidden={isMobileMenuOpen}>
                  <BrandLogo />
                </Styles.NavbarIcon>
              </Visibility>
              <Visibility visibleOn={['laptop', 'desktop']}>
                <Styles.NavbarIcon>
                  <BrandLogo color='currentColor' />
                </Styles.NavbarIcon>
              </Visibility>
            </Link>
            {isMobile && isMobileSubmenuOpen && (
              <MenuNavIcon
                icon={<IconBack />}
                label={intl.formatMessage(messages.closeMobileSubmenu)}
                onClick={() => setIsMobileSubmenuOpen(false)}
              />
            )}
          </Styles.Left>
          <Styles.Center
            onMouseEnter={isMobile ? undefined : openPrimaryMenu}
            onMouseLeave={isMobile ? undefined : closePrimaryMenu}
          >
            {!isMobile &&
              menuBlocks
                ?.filter(menu => menu.displayOn === 'desktop' || menu.displayOn === 'all')
                .map(menu => {
                  if (menu.ntExperiencesCollection.items.length > 0) {
                    return (
                      <Experience
                        passthroughProps={{
                          isInactive: isMobileMenuOpen,
                          isTransparent,
                          menu,
                          top: topBannerVisible ? topBannerHeight : 0,
                          toggleDesktopMenuCallback: openState => setIsPrimaryMenuOpen(openState)
                        }} // component props
                        experiences={menu.ntExperiencesCollection.items.map(item => {
                          return ExperienceMapper.mapExperience({
                            audience: item.nt_audience && { id: item.nt_audience.nt_audience_id },
                            config: item.nt_config,
                            id: item.sys.id,
                            name: item.nt_name,
                            type: item.nt_type,
                            variants: item.ntVariantsCollection.items.map(item => ({
                              /* to ensure the component displays the correct data and behaviour, any variant specific data/fn should be added here) */
                              id: item.sys.id,
                              menu: item
                            }))
                          });
                        })}
                        id={menu.sys.id}
                        key={menu.sys.id}
                        component={Submenu}
                        // TODO Ninetailed: add ESRLoadingComponent, after component conflict solve
                        // loadingComponent={ESRLoadingComponent}
                      />
                    );
                  }
                  return (
                    <Submenu
                      key={menu.sys.id}
                      menu={menu}
                      isInactive={isMobileMenuOpen}
                      top={topBannerVisible ? topBannerHeight : 0}
                      toggleDesktopMenuCallback={openState => setIsPrimaryMenuOpen(openState)}
                    />
                  );
                })}
          </Styles.Center>
          <Styles.Right>
            {!isMobile &&
              menuBlocks
                ?.filter(menu => menu.displayOn === 'desktopRight')
                .map(menu => (
                  <Styles.TopMenuContainer isActive key={menu.sys?.id}>
                    <ConditionalLink isLink={!!menu.urlRoute?.path} href={menu.urlRoute?.path} prefetch={false}>
                      <Styles.TopMenuItem variant='h6' $isClickable $styleProps={{}} style={{ color: menu.color }}>
                        {menu.text}
                      </Styles.TopMenuItem>
                    </ConditionalLink>
                  </Styles.TopMenuContainer>
                ))}
            {!isMobile &&
              staticLinks.map(item => {
                return (
                  <ConditionalLink
                    externalUrl={item.fullPageReload ? pathTo(item.url) : null}
                    isLink={item.url && !item.fullPageReload}
                    href={item.url}
                    key={item.link}
                    prefetch={false}
                    data-testid={item.dataTestId}
                  >
                    <Styles.TopMenuContainer isActive>
                      <Styles.TopMenuItem
                        variant='h6'
                        $isClickable
                        $styleProps={{}}
                        onClick={item.onClick ? item.onClick : undefined}
                      >
                        <FormattedMessage {...messages[item.link]} />
                      </Styles.TopMenuItem>
                    </Styles.TopMenuContainer>
                  </ConditionalLink>
                );
              })}
            <MenuNavIcon
              icon={<IconSearch />}
              label={intl.formatMessage(messages.search)}
              onClick={() => setIsSearchMenuOpen(!isSearchMenuOpen)}
              isHidden={isMobileMenuOpen}
            />
            <WishlistAction>
              {({ count }) => (
                <MenuNavIcon
                  icon={count < 1 ? <IconFavourite /> : <IconFavouriteFilled />}
                  label={intl.formatMessage(messages.wishlist)}
                  href={pathTo(paths.wishlist)}
                  onClick={() => headerInteraction.level1({ navigationL1: 'wishlist' })}
                  isHidden={isMobileMenuOpen}
                />
              )}
            </WishlistAction>
            <MenuNavIcon
              icon={<IconAccount />}
              label={intl.formatMessage(messages.login)}
              href={getUserProfilePath()}
              mobile
              onClick={() => {
                headerInteraction.level1({ navigationL1: hasValidAccessToken ? 'account' : 'login' });
                if (!hasValidAccessToken) {
                  router.query.auth = 'login';
                  router.push(router, undefined, { shallow: true });
                }
              }}
              isHidden={isMobileMenuOpen}
            />
            <MenuNavIcon
              icon={<IconShoppingBag />}
              count={cartCount.data}
              label={intl.formatMessage(messages.cart)}
              href={pathTo(paths.cart)}
              onClick={() => headerInteraction.level1({ navigationL1: 'cart' })}
              isHidden={isMobileMenuOpen}
            />
          </Styles.Right>
        </Styles.PrimaryMenuContainer>
        {/* Sticky Nav */}
        {isMobile && secondaryMobileMenuBlocks?.length > 0 && (
          <SecondaryMobileMenu menuBlocks={secondaryMobileMenuBlocks} />
        )}

        {/* Floating Button */}
        {isHomepage && stickyCTAMenuBlock && (
          <Styles.FloatingButtonWrapper>
            <Link href={stickyCTAMenuBlock.urlRoute?.path || pathTo(stickyCTAMenuBlock.path)} prefetch={false}>
              <Button size='small' color='accent'>
                <DynamicIcon id={stickyCTAMenuBlock.icon} />
                {stickyCTAMenuBlock.text}
              </Button>
            </Link>
          </Styles.FloatingButtonWrapper>
        )}

        {/* Menu Panes */}
        {isMobile && (
          <MenuPane
            name='Mobile Menu Pane'
            open={isMobileMenuOpen}
            top={topBannerVisible ? topBannerHeight : 0}
            withScrollLock
          >
            <>
              {menuBlocks
                ?.filter(menu => menu.displayOn === 'mobile' || menu.displayOn === 'all')
                .map(menu => {
                  if (menu.ntExperiencesCollection.items.length > 0) {
                    return (
                      <Experience
                        passthroughProps={{
                          firstMobileSubtitle: mobileSubmenuSubtitles && mobileSubmenuSubtitles[0],
                          isMobile,
                          isMobileSubmenuOpen,
                          menu,
                          toggleMobileMenuCallback: () => setIsMobileSubmenuOpen(!isMobileSubmenuOpen),
                          top: topBannerVisible ? topBannerHeight : 0 // needed for style
                        }} // component props
                        experiences={menu.ntExperiencesCollection.items.map(item => {
                          return ExperienceMapper.mapExperience({
                            audience: item.nt_audience && { id: item.nt_audience.nt_audience_id },
                            config: item.nt_config,
                            id: item.sys.id,
                            name: item.nt_name,
                            type: item.nt_type,
                            variants: item.ntVariantsCollection.items.map(item => ({
                              /* to ensure the component displays the correct data and behaviour, any variant specific data/fn should be added here) */
                              id: item.sys.id,
                              menu: item
                            }))
                          });
                        })}
                        id={menu.sys.id}
                        key={menu.sys.id}
                        component={Submenu}
                        // TODO Ninetailed: add ESRLoadingComponent, after component conflict solve
                        // loadingComponent={ESRLoadingComponent}
                      />
                    );
                  }

                  return (
                    <Submenu
                      key={menu.sys.id}
                      firstMobileSubtitle={mobileSubmenuSubtitles && mobileSubmenuSubtitles[0]} // needed for style
                      isMobile
                      isMobileSubmenuOpen={isMobileSubmenuOpen}
                      menu={menu}
                      toggleMobileMenuCallback={() => setIsMobileSubmenuOpen(!isMobileSubmenuOpen)}
                      top={topBannerVisible ? topBannerHeight : 0}
                    />
                  );
                })}
              {isMobile && !isMobileSubmenuOpen && (
                <Link href={paths.home} role='button'>
                  <Styles.AmpIcon src={AmpIcon} />
                </Link>
              )}
            </>
          </MenuPane>
        )}
        <MenuPane
          name='Search Menu Pane'
          open={isSearchMenuOpen}
          top={topBannerVisible ? topBannerHeight : 0}
          withScrollLock
        >
          {isSearchMenuOpen && <Search asPage={false} closeHandler={closeSearchMenu} />}
        </MenuPane>
      </Styles.GlobalNavContainer>
      {!isMobile && (
        <Backdrop
          zIndex={zIndexMasterList.navbar - 1}
          isOpen={isPrimaryMenuOpen}
          onClick={closePrimaryMenu}
          top={topBannerVisible ? topBannerHeight : 0}
        />
      )}
    </>
  );
}
